$change: #FAC616;
$challenge: #E8482B;
$competence: #428439;
$community: #153291;

@font-face {
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('HelveticaNeue.woff2') format('woff2');
}

@font-face {
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-display: swap;
  font-weight: bold;
  src: url('HelveticaNeueBold.woff2') format('woff2');
}

html {
  scroll-behavior: smooth;
}

html, body {
  padding: 0;
  margin: 0;
}

#root {
  min-height: 100vh;

  > .MuiContainer-root {
    padding-top: 25vh;
    @media screen and (max-height: 825px) {
      padding-top: 15vh;
    }
    @media screen and (max-height: 725px) {
      padding-top: 2rem;
    }

  }
}

.MuiSlider-root {
  .MuiSlider-track {
    display: none;
  }

  .MuiSlider-rail {
    background-color: grey;
    height: 1px;
  }
}

.MuiStep-root {
  p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border: 1px solid grey;
    border-radius: 50%;

    font-weight: bold;
    cursor: pointer;
  }
}

.MuiStepper-root {
  .MuiStep-root.step-active {
    &.step-change p {
      background: $change;
      border-color: $change;
      color: white;
    }

    &.step-challenge p {
      background: $challenge;
      border-color: $challenge;
      color: white;
    }

    &.step-competence p {
      background: $competence;
      border-color: $competence;
      color: white;
    }

    &.step-community p {
      background: $community;
      border-color: $community;
      color: white;
    }
  }
}