@font-face {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("HelveticaNeue.woff2") format("woff2");
}
@font-face {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-display: swap;
  font-weight: bold;
  src: url("HelveticaNeueBold.woff2") format("woff2");
}
html {
  scroll-behavior: smooth;
}

html, body {
  padding: 0;
  margin: 0;
}

#root {
  min-height: 100vh;
}
#root > .MuiContainer-root {
  padding-top: 25vh;
}
@media screen and (max-height: 825px) {
  #root > .MuiContainer-root {
    padding-top: 15vh;
  }
}
@media screen and (max-height: 725px) {
  #root > .MuiContainer-root {
    padding-top: 2rem;
  }
}

.MuiSlider-root .MuiSlider-track {
  display: none;
}
.MuiSlider-root .MuiSlider-rail {
  background-color: grey;
  height: 1px;
}

.MuiStep-root p {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border: 1px solid grey;
  border-radius: 50%;
  font-weight: bold;
  cursor: pointer;
}

.MuiStepper-root .MuiStep-root.step-active.step-change p {
  background: #FAC616;
  border-color: #FAC616;
  color: white;
}
.MuiStepper-root .MuiStep-root.step-active.step-challenge p {
  background: #E8482B;
  border-color: #E8482B;
  color: white;
}
.MuiStepper-root .MuiStep-root.step-active.step-competence p {
  background: #428439;
  border-color: #428439;
  color: white;
}
.MuiStepper-root .MuiStep-root.step-active.step-community p {
  background: #153291;
  border-color: #153291;
  color: white;
}

